import _ from 'lodash';
import api from '@/api';
import base64toPDF from '../../lib/base64ToPDF';
import Cookies from '@/portal-cookie';
import { message } from 'antd';

const initialState = {
	invoices: [],
	selectedForPayment: [],
	paymentErrors: [], // errors from authorize.net when attemping to capture payment
	invoicePaymentTotal: 0,
	cardPaymentFeesTotal: 0,
	processingPayment: false,
	processingSuccess: false,
	processingErrors: [], // errors occured when writing to SAP w/ IncomingPayment doc
	successfulTransactionId: '', // this comes from authorize.net
	cardCode: '',
	cardType: '',
	cardName: '',
	loading: false,
	loaded:  false,
	loadedVendorCode: false,
	showInvoicePaymentDialog: false,
	showPreviewPDFDialog: false,
	showContactDialog: false,
	pdfModalLoading: false,
	pdfModalData: null,
	pdfFilePreview: null,
	pdfModalKey: null,
};

export const types = {
	SET_STATE: 'INVOICE/SET_STATE',
	SET_SELECTED: 'INVOICE/SET_SELECTED',
	UNSET_SELECTED: 'INVOICE/UNSET_SELECTED',
};

const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };

		case types.SET_SELECTED:
			return { 
				...state,
				selectedForPayment: [
					...state.selectedForPayment,
					action.data,
				]
			};

		case types.UNSET_SELECTED:
			return { 
				...state,
				selectedForPayment: state.selectedForPayment
					.filter((record) => record.DocEntry !== action.data.DocEntry)
					.map((record) => ({...record}))
			};
		default:
			return state;
	}
};

export default reducers;

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),
	
	resetContactDialog: () => ({
		type: types.SET_STATE, data: {
			showContactDialog: false,
		},
	}),
	
	resetInvoicePaymentDialog: () => ({
		type: types.SET_STATE, data: {
			showInvoicePaymentDialog: false,
			invoicePaymentTotal: 0,
			cardPaymentFeesTotal: 0,
			processingPayment: false,
			processingSuccess: false,
			processingErrors: [],
			successfulTransactionId: ''
		},
	}),
	
	resetInvoicePDFDialog: () => ({
		type: types.SET_STATE, data: {
			showInvoicePDFDialog: false,
		},
	}),
	
	reset: () => ({ type: types.SET_STATE, data: { ...initialState } }),

	setSelected: (record) => ({ type: types.SET_SELECTED, data: record }),

	unsetSelected: (record) => ({ type: types.UNSET_SELECTED, data: record}),

	loadInvoices: () => async (dispatch, getState) => {
		const state = getState(),
			  loading = state.invoices.loading,
			  cardCode = state.invoices.cardCode,
			  cardType = state.invoices.cardType;

		if(loading) {
			return false;
		}

		dispatch(actions.setState({ loading: true, loaded: false }));
		
		try {
			let response = await api.get('/invoices', {
				params: {
					cardCode,
					cardType
				},
			});

			const invoices = response;
			// const invoices = response.map((invoice) => {
			// 	return { ...invoice, 'PayAmount': invoice.DocumentTotal }; 
			// });

			const cardNameProp = cardType === 'C' ? 'CustomerName' : 'VendorName';

			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loaded: true,
					invoices: invoices,
					cardName: response.length > 0
						? response[0][cardNameProp]
						: '',
				},
			});
		} catch(err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loaded: true,
					invoices: [],
					cardName: '',
				},
			});
		}
	},
	
	loadVendorCode: () => async (dispatch) => {
		try {
			const userKey = Cookies.get('TS_UserKey', '');
			const response = await api.get('/invoices/vendor-code', {
				params: {
					userKey: userKey,
				},
			});

			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loadedVendorCode:  true,
					cardCode: response.BPCode,
					cardType: response.BPType,
				},
			});
		} catch(err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loadedVendorCode:  true,
					cardCode: '',
					cardType: '',
				},
			});
		}
	},

	loadInvoicePreview: (docEntry, objType) => async (dispatch) => {
		dispatch(actions.setState({ pdfModalLoading: true }));
		try {
			const response = await api.get('/invoices/preview', {
				params: {
					docEntry,
					objType
				}
			});

			let fileData = response.FileData;
			let pdf = base64toPDF(fileData);

			dispatch(actions.setState({ pdfModalKey: docEntry }))
			dispatch(actions.setState({ pdfModalData: fileData }));
			dispatch(actions.setState({ pdfFilePreview: pdf }));

			dispatch(actions.setState({ pdfModalLoading: false }));
		} catch (e) {
			console.error(e);
			message.error('Could not load invoice preview');
			dispatch(actions.setState({ pdfModalLoading: false }));
		}
	},

	processPayment: (paymentForm) => async (dispatch, getState) => {
		// should send invoice keys, query and confirm totals match
		const state                = getState(),
			  invoices             = state.invoices.invoices,
			  invoiceDocNums       = invoices.filter((invoice) => invoice.Checked).map(invoice => invoice.DocNum),
			  invoicePaymentTotal  = state.invoices.invoicePaymentTotal,
			  cardPaymentFeesTotal = state.invoices.cardPaymentFeesTotal;
				

		dispatch(actions.setState({ processingPayment: true }));
		try {
			let paymentResults = await api.post('/payments/capture', {
				paymentForm,
				invoicePaymentTotal,
				cardPaymentFeesTotal
			});

			if (paymentResults.successful) {
				// set pending payment cookie
				let pendingCookie = Cookies.get('pendingInvoices'),
					currentPending = null;
				if (pendingCookie) {
					currentPending = JSON.parse(decodeURIComponent(Cookies.get('pendingInvoices')));
				}

				if (currentPending) {
					const newPending = _.union(currentPending, invoiceDocNums);
					Cookies.set('pendingInvoices', JSON.stringify(newPending));
				} else {
					Cookies.set('pendingInvoices', JSON.stringify(invoiceDocNums));
				}
				// need to make sure all info is sent, the final payment res does not have a lot
				dispatch(actions.recordPaymentToSAP(paymentResults.paymentData));
			}
		} catch(e) {
			//console.error('it done went wrong', e);
			message.error(e.response.data.errors);
			dispatch(actions.setState({ 
				processingPayment: false,
				processingSuccess: false,
				paymentErrors: [ ...e.response.data.errors ],
			}));
		}
	},

	recordPaymentToSAP: (paymentData) => async (dispatch, getState) => {
		const state            = getState(),
			  invoices         = state.invoices.invoices,
			  cardCode         = state.invoices.cardCode,
			  creditCardFees   = state.invoices.cardPaymentFeesTotal,
			  selectedInvoices = invoices.filter((invoice) => invoice.Checked),
			  invoiceDocNums   = selectedInvoices.map(invoice => invoice.DocNum) || [];

		let paymentResult;
		try {
			if (Boolean(paymentData.paymentType === 'CC')) {
				const invoiceCheckResults = await api.get('/invoices/audit', {
					params: {
						invoiceDocNums
					},
				});

				if (invoiceCheckResults.invoicesLeftToCreate.length > 0) {
					const invoiceData = {
						invoiceDocNums: invoiceCheckResults.invoicesLeftToCreate.join(', '),
						cardCode,
						LineItem: {
							Item: 'CC Fee',
							Qty: 1,
							LineTotal: parseFloat(creditCardFees).toFixed(2)
						},
					};

					const invoiceResult = await api.post('/invoices/create', {
						params: {
							invoiceData
						}
					});
	
					if (invoiceResult.successful) {
						selectedInvoices.push({
							DocEntry: invoiceResult.feeInvoiceKey,
							DocumentTotal: parseFloat(creditCardFees).toFixed(2),
							ObjType: '13'  // CC Fee treated as regular invoice
						});
					} else {
						console.error(`Could not create CC fee invoice for invoice(s) with doc nums: ${invoiceCheckResults.invoicesLeftToCreate.join(', ')}`);
					}
				}

				// creates IncomingPayment Document
				paymentResult = await api.post('/invoices/payment', {
						params: {
							transaction: paymentData,
							documents: selectedInvoices,
							cardCode,
						}
				});
				
			} else {
				// creates IncomingPayment Document
				paymentResult = await api.post('/invoices/payment', {
					params: {
						transaction: paymentData,
						documents: selectedInvoices,
						cardCode,
					}
				});
			}

			if (paymentResult.successful) {
				// remove any doc nums that were in pending invoices and reset the cookie
				let pendingCookie = Cookies.get('pendingInvoices'),
					pendingInvoices = null;
				
				if (pendingCookie) {
					pendingInvoices = JSON.parse(decodeURIComponent(Cookies.get('pendingInvoices')));
				}
				
				if (pendingInvoices) {
					const newPendingInvoices = _.without(pendingInvoices, invoiceDocNums);
					Cookies.set('pendingInvoices', JSON.stringify(newPendingInvoices));
				}

				message.success('Your payment has processed successfully and has been recorded');
				
				dispatch(actions.setState({ 
					processingPayment: false,
					processingSuccess: true,
					successfulTransactionId: paymentResult.transactionId
				}));
			}

		} catch(e) {
			console.error(e.response.data.errors);
			message.error('Your payment has processed but there was an issue updating the invoice. Please do not pay again.');
			dispatch(actions.setState({ 
				processingPayment: false,
				processingSuccess: false,
				processingErrors: [ 'There was an issue recording your payment. Please do not pay again. Your payment is pending.', e.response.data.errors ]
			}));
		}
	},

};
